<template>
  <v-card :loading="loading_grava_senha">
    <v-card-title>
      <v-row>
        <v-col v-if="!pessoa.id">Novo usuario</v-col>
        <v-col v-else>Editar usuario</v-col>
        <v-col v-show="pessoa.id" class="text-right">
            {{ pessoa.username }} # {{ pessoa.id || '' }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-form ref="form_usu">
      <v-card-text>
        <v-row>
          <v-col>
            <v-divider class="mb-3"></v-divider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              clearable
              label="Nome Completo"
              outlined
              required
              :rules="[e=>(e!==undefined && e.length>=2) || 'CAMPO OBRIGATORIO']"
              v-model="pessoa.first_name"
              :disabled="disabled"
            >
            </v-text-field>  
          </v-col>
          <v-col cols="6">
            <v-text-field
              clearable
              label="EXCLUIR"
              outlined
              required
              :rules="[e=>(e!==undefined && e.length>=2) || 'CAMPO OBRIGATORIO']"
              v-model="pessoa.last_name"
              :disabled="disabled"
            >
            </v-text-field>  
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              clearable
              :loading="loading_check_username"
              label="Usuário"
              required
              :rules="regra_usuario"
              outlined
              @keyup="check_username()"
              v-model="pessoa.username"
              :disabled="disabled"
              :messages="msg_username"
            >
            </v-text-field>  
          </v-col>
          <v-col cols="6">
            <v-text-field
              clearable
              :loading="loading_check_email"
              label="E-mail"
              required
              :rules="regra_email"
              outlined
              v-model="pessoa.email"
              :disabled="disabled"
            >
            </v-text-field>  
          </v-col>
        </v-row>
        <!-- passwords -->
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              label="Senha"
              outlined
              v-model="pessoa.password"
              required
              :rules="regra"
              prepend-inner-icon="mdi-key"
              :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'"
              :type="tipo?'text':'password'"
              @click:append="tipo = !tipo"
              :disabled="disabilita_password"
            >
            </v-text-field>  
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Confirme a senha"
              outlined
              v-model="pessoa.password2"
              :rules="regra_comparacao_senhas"
              required
              :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'"
              :type="tipo ? 'text' : 'password'"
              @click:append="tipo = !tipo"
              :disabled="disabilita_password"
            >
            </v-text-field>  
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-right">
            <v-btn 
              :loading="loading_grava_senha"
              outlined block color="green" 
              @click="gravar_usuario()"
              :disabled="disabled"
            ><v-icon class="pr-1">mdi-content-save-outline</v-icon>{{btn_gravar}}</v-btn>
          </v-col>
        </v-row> 
      </v-card-text>
      <v-card-text else>
        <v-card-title>{{msg}}</v-card-title>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import api from '@/http';

export default {
  name:"DadosAcesso",
  components:{  
    // MySnackbar:()=>import('../uteis/mySnack.vue')
  },
  props: {  
  },
  data:()=>({
    tipo: false,
    disabled: false,
    status: true,
    msg: '',
    msg_username: '',
    // name_regra: [e=> e && e.length>=4 || 'Usuario invalido'],
    loading_grava_senha: false,
    loading_check_username: false,
    loading_check_email: false,
    resposta_username: false,
  }),
  methods:{
    check_username(){
      this.loading_check_username = true
      
      if(this.pessoa.username!==undefined && this.pessoa.username.length>3){
        api.get('contas/usuarios/', {params:{username:this.pessoa.username}})
        .then((res)=>{
          if(res.data.length>0){
            this.resposta_username = false
            this.msg_username = 'Username inválido'
          }else{
            this.resposta_username = true
            this.msg_username = 'Username válido'
          }
        })
      }
      this.loading_check_username = false
    },
    async novo_usuario(){ 
      if(this.$refs.form_usu.validate()){
        this.disabled = true
        this.loading_grava_senha = true
        const res = await api.post('contas/novo/', this.pessoa)
        if(res.status==201){ 
          this.$emit('tab_emit',1)
          this.$store.dispatch('pessoa', res.data)
          this.msg = 'Criado com sucesso :)'
          this.status = false // esconde o card principal pra mostrar a msg de gravado com sucesso
        }else{
          this.msg = res.response.data.username[0].toString()
        }
        this.loading_grava_senha = false
      }
      this.disabled = false
    },
    async alterar_usuario(){
      var obj = Object.fromEntries(Object.entries(this.pessoa)
          .filter(([key]) => !key.includes('avatar'))
          .filter(([key]) => !key.includes('carimbo'))
      )
      const res = await api.put(`contas/usuario/${this.pessoa.id}/`, obj)
      res
    },
    gravar_usuario(){
      if(this.pessoa.id){
        this.alterar_usuario()
      }else{
        this.novo_usuario()
      }
      
    },
    fecha(){
      this.snb = false
    }
  },
  computed:{
    ...mapGetters(['pessoa']),
    tem_id(){
      return this.pessoa.id?true:false
    },
    regra_usuario(){
      let username = this.pessoa.username !== undefined ? this.pessoa.username : ''
      return [
        username.length >= 3 || 'Username deve ter mais de 3 caracteres',
        this.resposta_username || 'USUARIO JA CADASTRADO',
      ]
    },
    regra_email(){
      var str = this.pessoa.email!==undefined ? this.pessoa.email : ''
    
      var reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      let email = str.match(reg_email)
      
      return [
        email?true:false||'EMAIL INVÁLIDO',
      ]
    },
    regra(){


        var str = this.pessoa.password!== undefined? this.pessoa.password : ''
        var um_numero = /[0-9]{1}/;
        var uma_minus = /[a-z]{1}/;
        var uma_maius = /[A-Z]{1}/;
        var um_caracter_especial = /[!@#$%ˆ&*()_=+]{1}/;
        let numero = str.match(um_numero)
        let minusc = str.match(uma_minus)
        let maiscu = str.match(uma_maius)
        let caract = str.match(um_caracter_especial)
        
        // console.log(numero?false:true)
        
        return [
          this.pessoa.password===undefined || this.pessoa.password.length >=8 ||'A senha precisa ter um mínimo de 8 caracteres',
          maiscu?true:false || 'DEVE TER O MINIMO DE UMA LETRA MAIUSCULAS',
          minusc?true:false || 'DEVE TER O MINIMO DE UMA LETRA MINUSCULAS',
          caract?true:false || 'DEVE TER O MINIMO DE UM CARACTER ESPECIAL',
          numero?true:false || 'DEVE TER O MINIMO DE UM NUMERO',
        ]
    },
    regra_comparacao_senhas(){
      return [this.pessoa.password == this.pessoa.password2||'A senha não confere',]
    },
    btn_gravar(){
      return this.pessoa.id?'alterar dados de acesso':'salvar novo registro'
    },
    disabilita_password(){  return this.pessoa.id!==undefined?false:true}
  }
}
</script>